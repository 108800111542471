import {
  Play,
  XCircle,
  Check,
  CircleNotch,
  MusicNotesPlus,
} from "@phosphor-icons/react";
import PlayerStore from "../stores/PlayerStore";
import axios from "axios";
// eslint-disable-next-line import/no-unresolved
import { toast } from "sonner";
import { BASE_URL } from "../utils/Constants";

function TrackListItem({ track, index }) {
  const { song, playSong, auth, addSongs, songs } = PlayerStore();

  function formatDuration(duration_ms) {
    if (!duration_ms) return "00:00";
    const secsNum = parseInt(duration_ms.toString(), 10);
    const hours = Math.floor(secsNum / 3600) % 24;
    const minutes = Math.floor(secsNum / 60) % 60;
    const seconds = secsNum % 60;

    return [hours, minutes, seconds]
      .map((num) => (num < 10 ? "0" + num : num))
      .filter((num, i) => num !== "00" || i > 0)
      .join(":");
  }

  async function saveSong() {
    track.LOADING = true;
    track.REQUESTED = false;
    track.ERROR = false;
    addSongs(songs);

    // const artist = track.ARTISTS.map((artist) => artist.ART_NAME).join(", ");
    const artist = track.ART_NAME;
    const album = track.ALB_TITLE;
    const name = track.SNG_TITLE;
    const song_id = track.SNG_ID;

    const data = { artist, album, name, song_id };

    axios
      .post(`${BASE_URL}/notion/database`, data, {
        params: {
          session: auth.session,
          checkForm: auth.checkForm,
        },
      })
      .then((res) => {
        track.REQUESTED = true;
        toast.success(res.data.message, {
          classNames: { toast: "bg-green-600" },
        });
      })
      .catch((err) => {
        track.ERROR = true;
        toast.error(err.response.data.message, {
          classNames: { toast: "bg-rose-700" },
        });
      })
      .finally(() => {
        track.LOADING = false;
        addSongs(songs);
      });
  }

  return (
    <div className="group h-[56px] rounded hover:bg-white/5 ">
      <div className="flex h-full items-center px-2 text-white/80 ">
        <div className="mr-4 flex w-[30px] min-w-0 items-center justify-end text-sm tabular-nums">
          {index + 1}
        </div>
        <div className="flex min-w-0 flex-1 items-center justify-start gap-4">
          <div className="relative flex h-10 w-10 overflow-hidden rounded">
            <div className="h-[40px] w-[40px]">
              <img
                alt="album cover"
                src={`https://e-cdns-images.dzcdn.net/images/cover/${track.ALB_PICTURE}/40x40-000000-80-0-0.jpg`}
                width="40"
                height="40"
              />
            </div>
            <div className="x absolute left-0 top-0 flex h-full w-full items-center justify-center text-white opacity-0 transition-opacity group-hover:opacity-100">
              <button
                onClick={() => playSong(track)}
                type="button"
                className="rounded-full bg-rose-600 p-1.5"
              >
                <Play className="h-4 w-4" weight="fill" />
              </button>
            </div>
          </div>
          <div className="flex min-w-0 flex-1 flex-col">
            <div className="flex flex-col text-start">
              <span
                className={`truncate text-sm ${track.SNG_ID == song?.SNG_ID ? "text-rose-600" : ""}`}
              >
                {track.SNG_TITLE}
              </span>
              <div className="truncate gap-1 inline-flex items-center text-white/50">
                {track.EXPLICIT_LYRICS == 1 && (
                  <div className="text-xs font-semibold flex items-center justify-center bg-white/50 size-4 rounded-sm text-black p-[4px]">
                    E
                  </div>
                )}

                <span className="text-sm">
                  {track.ARTISTS.map((artist) => artist.ART_NAME).join(", ")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-8 hidden w-[15%] min-w-0 items-center justify-start lg:flex">
          <div className="truncate">
            <span className="text-sm">{track.ALB_TITLE}</span>
          </div>
        </div>
        <div className="ml-8 flex w-[40px] min-w-0 items-center justify-start">
          <span className="text-sm">{formatDuration(track.DURATION)}</span>
        </div>
        <div className="flex w-[40px] min-w-0 items-center justify-end">
          <button
            onClick={saveSong}
            disabled={track.LOADING || track.REQUESTED}
            className="text-primary-500 transition-colors duration-300 hover:animate-pulse"
          >
            {!track.LOADING && !track.REQUESTED && !track.ERROR && (
              <MusicNotesPlus className="h-5 w-5" />
            )}

            {track.LOADING ? (
              <CircleNotch className="h-5 w-5 animate-spin text-yellow-500" />
            ) : track.REQUESTED ? (
              <Check className="h-5 w-5 text-green-500" />
            ) : track.ERROR ? (
              <XCircle className="h-5 w-5 text-rose-600" />
            ) : null}
          </button>
        </div>
      </div>
    </div>
  );
}

export default TrackListItem;
